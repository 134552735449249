<template>
  <div>
    <portal to="page-top-title">Edit Offer #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'offers-single', params: { id: id } }" icon="fa-eye" text>Back to Offer</btn>
    </portal>
    <box-form action="edit" data-prop="formData" :url="`/offers/${id}`" redirect="offers-list" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" required/>
          </column-input>
          <column-input :md="2">
            <form-group-input name="lifetime" type="number" :model="formData.lifetime">
              <p slot="help">* Use "0" for Infinite lifetime</p>
            </form-group-input>
          </column-input>
          <column-input :md="2">
            <form-group-check name="compare_clicker_data" :model="formData.compare_clicker_data"/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="redirect_url" :model="formData.redirect_url">
              <p slot="help">* This field can be blank. URL from settings will be used.</p>
            </form-group-input>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="url" :model="formData.url">
              <template slot="info">
                <p>
                  <span>Macros:</span>
                  <loader v-if="!macros.length"/>
                  <code v-for="(macro, i) in macros" :key="`macro-${i}`">{{ macro.code }}</code>
                </p>
              </template>
            </form-group-input>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import Loader from '@/views/components/simple/Loader'
import Form from '@/modules/utils/Form'

export default {
  name: 'OffersEdit',
  metaInfo () {
    return { title: `Offer #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput,
    FormGroupCheck,
    Loader
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      macros: [],
      loaded: false,
      formData: {
        name: Form.types.input(),
        lifetime: Form.types.input(),
        compare_clicker_data: Form.types.boolean(),
        active: Form.types.boolean(),
        redirect_url: Form.types.input(),
        url: Form.types.input()
      }
    }
  },
  created () {
    this.loadEntity()
    this.loadMacros()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/offers/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.lifetime.value = data.lifetime
          this.formData.compare_clicker_data.value = data.compare_clicker_data
          this.formData.active.value = data.active
          this.formData.redirect_url.value = data.redirect_url
          this.formData.url.value = data.url

          this.loaded = true
        })
    },
    loadMacros () {
      this.$http
        .get('/offers/macros', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.macros = data.map((macro) => { return { code: macro.code, name: macro.name } })
        })
    }
  }
}
</script>
